import * as React from "react"
import styled from "@emotion/styled"
import { Link } from "gatsby"
import { gs } from "./GlobalStyles"

const UlNav = styled.ul`
  display: flex;
  gap: 20px;
  list-style-type: none;
  padding: 0;
  margin: 10px 0 0 0;
`
const Linky = styled(Link)`
  display: block;
  text-decoration: none;
  position: relative;
  padding: 2px 0;
  font-size: ${gs.size.font.lg};
  color: ${gs.color.secondary};
  @media screen and (min-width: ${gs.size.screen.md}) {
    font-size: ${gs.size.font.xl};
  }
  &::before {
    content: "";
    pointer-events: none;
    transition: width 120ms ease-in-out, height 120ms ease-in,
      border-color 120ms ease-in;
    box-sizing: border-box;
    display: block;
    height: 100%;
    width: 0;
    position: absolute;
    background-color: transparent;
    border: 0 solid ${gs.color.translucent};
    border-bottom-width: 3px;
  }
  &:not(.active) {
    &:hover,
    &:focus-visible {
      &::before {
        width: 100%;
      }
    }
  }
  &.active {
    &::before {
      width: 100%;
      border-color: ${gs.color.blue};
    }
    &:hover,
    &:focus-visible {
      cursor: default;
      color: ${gs.color.blue};
    }
  }
`

const SubMenu = () => {
  return (
    <nav
      aria-label="Multiple Part Article Menu"
      style={{ marginBottom: "20px" }}
    >
      <UlNav>
        <li>
          <Linky to="/case-studies/loara" activeClassName="active">
            July 2023 Update
          </Linky>
        </li>
        <li>
          <Linky
            to="/case-studies/loara/accessibility"
            activeClassName="active"
          >
            Accessibility Study (2022)
          </Linky>
        </li>
      </UlNav>
    </nav>
  )
}

export default SubMenu
